//external fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;
$link-color: 			      #faa62a;
$link-hover-color:      #faa62a;
$extra-dark-green:      #30392f;
$dark-green:            #1d602d;
$dark-green-btn:        #67775d;
$medium-green:          #38982c;
$light-green:           #5eb951;
$light-green-btn:       #f0f5ec;
$extra-light-green:     #dfe7da;
$orange:                #faa62a;
$dark-grey:             #676767;
$medium-grey:           #e9e9e4;
$light-grey:            #f4f4ef;
$grey:                  #a9ada7;
$clay-ash:              #c4cdc0;
$willow-grove:          #67775d;
$pampas:                #f4f4ef;
$storm-dust:            #626260;
$gravel:                #4a4c49;
$alto:                  #d9d9d9;



// Fonts
$OpenSans: 'Open Sans';
$Nunito:    'Nunito';
    $light: 300;
    $regular: 400;
    $semi-bold: 600;
    $bold:  700;
$Lato-Light: 'Lato-Light';



@font-face{
    font-family: $Lato-Light;
    src: url("../fonts/Lato-Light.ttf");
    src: url('../fonts/lato-light-webfont.otf');
    src: url('../fonts/lato-light-webfont.eot');
    src: url('../fonts/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-light-webfont.woff2') format('woff2'),
         url('../fonts/lato-light-webfont.woff') format('woff'),
         url('../fonts/lato-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
// Mixins

@mixin transition($transition) {
  -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin transform($transition) {
  -webkit-transform: $transition;
    -moz-transform: $transition;
    -ms-transform: $transition;
    -o-transform: $transition;
    transform: $transition;
}
@mixin vertical-align($position) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

//	Bootstrap Navbar

	$grid-float-breakpoint:  100%; //** Point at which the navbar becomes uncollapsed.
	$navbar-default-toggle-icon-bar-bg: $dark-green-btn; //** Hamburger Menu icon
    $navbar-default-toggle-border-color: $extra-light-green;
    $navbar-height: 115px;
    $navbar-margin-bottom: 0;
	$navbar-default-bg: transparent;
	$navbar-default-toggle-hover-bg: transparent;