p, .p1{
	font-family: $OpenSans;
    font-weight: $regular;
    font-size: 17px;
    line-height: 26px;
    color: $gravel;
}

.p2, .p2 > p{
    font-family: $OpenSans;
    font-weight: $regular;
    font-size: 14px;
    line-height: 24px;
    color: $gravel;
}

.h1, h1{
    font-family: $OpenSans;
    font-weight: $regular;
    font-size: 88px;
    line-height: 1;
    text-transform: uppercase;
}

.h2, h2{
    font-family: $OpenSans;
    font-weight: $regular;
    font-size: 52px;
    line-height: 1;
    @media(max-width: 767px){
        font-size: 3em;
    }
}

.h3, h3{
    font-family: $OpenSans;
    font-weight: $light;
    font-size: 36px;
    line-height: 1;
    text-transform: capitalize;
    margin: 5% auto;
    @media(max-width: 767px){
        font-size: 2.2em;
    }
}

.h4, h4{
    font-family: $OpenSans;
    font-weight: $bold;
    font-size: 24px;
    line-height: 34px;
    color: $gravel;
    @media(max-width: 767px){
        font-size: 1.5em;
    }
}

.h5, h5{
    font-family: $OpenSans;
    font-weight: $bold;
    font-size: 15px;
    line-height: 24px;
    color: $storm-dust;
    @media(max-width: 767px){
        font-size: 1em;
    }
}

.h6, h6{
    font-family: $OpenSans;
    font-weight: $regular;
    font-size: 16px;
    line-height: 28px;
    @media(max-width: 767px){
        font-size: 1em;
    }
}

.f60{
    font-size: 60px;
}

//Carmel Catholic Highschool Page

.postid-216 .f60 {
    font-size: 50px;
}

ol li,
ul li,
li{
    font-family: $OpenSans;
    font-size: 18px;
    line-height: 26px;
    font-weight: $light;
    color: $gravel;
}

a{
    color: $link-color;
    text-decoration: underline;
}

a:active{
    color: $link-hover-color;
}

a:hover{
    color: $link-hover-color;
}

a.btn {
    text-decoration: none;
}

a:focus{
    outline: none;
}

.list-arrow{
    ul{
        list-style-position: outside;
        list-style-type: none;
        padding: 0;
        margin-left: 1em;
        > li:before {
            content: "\00BB \0020";
            list-style-position: outside;
            margin-left: -1em;
            color: $orange;
        }   
    }
}