body,html{
    font-family: $OpenSans;
}
.wp-content-editor-container{
	html{
		overflow: visible;
	}
}
br{
	@media(max-width: 767px){
		display: none;
	}
}
html {
    overflow-x: hidden;
}
main.main{
	padding: 0;
}
.btn,a{
	@include transition( all 0.3s);
}
*:focus {
	outline:none !important;
}

p:empty {
    display: none;
}

:focus{
    outline: 0;
}
.btn.active{
	box-shadow: none;
}

.font-light{
	font-weight: $light;
}
.font-regular{
	font-weight: $regular;
}
.font-bold{
	font-weight: $bold;
}

svg.style-svg,
.cls-1,
.cls-2,
.cls-0{
    fill: $extra-light-green !important;
    max-height: 50px;
    max-width: 150px;
}


svg.style-svg:hover{
	fill: $orange !important;
	svg.style-svg,
	.cls-1,
	.cls-2,
	.cls-0 {
    	fill: $orange !important;
	}
}
.gmap {
    padding: 0;
}

#map{
	height: 580px;
    max-width: 100%;
    padding: 0;
    @media (min-width: 767px){
	    border-right: 5px solid #fff;
	    border-bottom: 10px solid #fff;
	}
}


/*
|--------------------------------------------------------------------------
| Utility Classes
|--------------------------------------------------------------------------
*/
.btn-responsive {
	@media (max-width: 767px){
    	text-align: center;
    	margin: 5% auto;
	}
}
.uppercase{
	text-transform: uppercase;
}
.capitalize{
	text-transform: capitalize;
}
.italic {
    font-style: italic;
}
.dark-grey{
	color: $dark-grey;
}
.no-padding{
	padding: 0;
}
.pointer{
	cursor: pointer;
}
.inline{
	display: inline;
}
.block{
	display: block;
}
.video {
    margin: 25px 0;
}
.em2{
	font-size: 2em;
}
.heading-medium-green{
	h1,.h1,h2,.h2,h3,.h3,h4,.h4{
		color: $medium-green;
	}
}
.medium-green{
	color: $medium-green;
}
.willow-grove{
	color: $willow-grove;
}
.bg-color-pampas{
	background-color: $pampas;
}
.font-bold{
	font-weight: $bold;
}
.medium-green-hover{
	&:focus{
		color: $medium-green;
	}
	&:active{
		color: $medium-green;
	}
	&:hover{
		color: $medium-green;
	}
}
.dark-green{
	color: $dark-green;
}
.text-white{
	color: #fff;
}
.text-black{
	color: #000 !important;
}
.text-light{
	font-weight: $light;
}
.text-decoration-none{
	text-decoration: none;
	*{
		text-decoration: none;
	}
}
.text-decoration-none-hover{
	&:focus{
		text-decoration: none;
	}
	&:active{
		text-decoration: none;
	}
	&:hover{
		text-decoration: none;
	}
}
.content-contained > .row {
    max-width: 1500px;
    margin: 0 auto;
}
.border-top-bottom:before,
.border-top-bottom:after {
	@media (min-width: 767px){
		content: '';
	    height: 3px;
	    background: $extra-light-green;
	    width: 100%;
	    float: right;
	}
}
.border-top-bottom:before{
 	margin-bottom: 10%;
 }
.border-top-bottom:after {
    margin-top: 10%;
}

.col-img > img,
.col-img > p > img, 
.col-img > p {
    margin: 0 auto;
} 
.col-img p img, .col-img p {
    margin: 0 auto;
}
.col-img-bottom > p img,
.col-img-bottom > p{
    margin-bottom: 0;
}
.section-border-top{
	border-top: 2px solid $extra-light-green;
}
.section-border-bottom{
	border-bottom: 2px solid $extra-light-green;
}
.iframe-box-shadow{
	iframe{
		box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.5);
	}
}
.iframe-mobile-responsive{
	iframe{
		@media(max-width: 992px){
			width: 100%;
		}
	}
}
.img-box-shadow{
	img {
    	box-shadow: 0px 15px 45px -10px #000;
	}
}
.default-img-margin,
.quote-img{
	margin: 5% 0;
}
.extra-margin-width{
	.row{
		margin: 0 5%;
	}
}

@media (min-width: 767px){
	.display-table{
		display: table;
		width: 100%;
		> div {
	    	display: table-cell;
	    	vertical-align: middle;
	    	float: none;
		}
	}
	.box-shadow {
		> div {
    		box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
    	}
	}
	.balance-box > .row {
	    display: table;
	    width: 100%;
	 }
	.balance-box > .row > .col-sm-6 {
	    display: table-cell;
	    vertical-align: middle;
	    float: none;
	}
	.balance-box > .row > .col-sm-15 {
	    display: table-cell;
	    vertical-align: middle;
	    float: none;
	}
}

.ytp-large-play-button-bg{
	fill: #fff !important
}
.flex-container{
	display: flex;
	@media (max-width: 767px){
		display: block;
	}
}
.flex-columns{
	display: flex;
	flex-direction: column;
}
.flex{
	display: flex;
}
.flex-1{
	flex: 1;
}
.flex-2{
	flex: 2;
}
.vimeo-video > div {
    padding-bottom: 56.67% !important;
}
.margin-none{
	margin: 0;
}
.margin-50px{
	margin: 50px;
}
.margin-5px{
	margin: 5px;
}
.flex-row-widget > p{
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.nav-pills a{
	font-weight: 500;
}
.fa-stack-2x {
    font-size: 2em;
}
.fa-lg{
	font-size: 1.5em;
}
.app-btns{
	img {
    	margin-bottom: 10px;
	}
}
.double-btn-spacing{
	margin-left: 5% !important;
	margin-right: 5% !important;
}
.margin-top-25{
	margin-top: 25px;
}
.margin-bottom-25{
	margin-bottom: 25px;
}
.padding-top-25{
	margin-top: 25px;
}
.padding-bottom-25{
	margin-bottom: 25px;
}
.margin-top-50{
	margin-top: 50px;
}
.margin-bottom-50{
	margin-bottom: 50px;
}
.padding-top-50{
	margin-top: 50px;
}
.padding-bottom-50{
	margin-bottom: 50px;
}
.flex-center{
	@media(min-width:767px){
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.visibility-hidden{
	visibility: hidden;
}
.bg-white{
	background-color: #fff;
}
.carbon-jquery-ui{
	z-index: 1000 !important;
}
.box-shadow-cover{
	box-shadow: inset 0 0 0 1000px rgba(0,0,0,.3);
}
/*
|--------------------------------------------------------------------------
| Utility Classes -- Animation
|--------------------------------------------------------------------------
*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}
#town-progress {
    position: absolute;
    z-index: 1000;
    top: 25%;
    left: 15%;
}
#calendar-progress {
    position: absolute;
    z-index: 1000;
    top: 25%;
    left: 50%;
    @media(max-width: 767px){
    	top: 50%;
    }
}