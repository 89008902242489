/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
    
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}


/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Small Devices, Tablets */
@media (max-width : 767px) {
	ul#menu-main-menu-column-2.navbar-nav {
    	margin-top: -23.75px !important;
    	margin-bottom: 0;
	}
	ul#menu-main-menu-column-1.navbar-nav {
    	margin-top: 0;
	}
	header nav#main-navigation ul:not(.fgl-dropdown-menu)>li{
		margin: 0;
	}
	.main-nav-col3{
		padding: 0;
		> .widget{
			border-top: 0 !important;
			border-bottom: 2px solid $grey;
		}
	}
	.main-nav-col3 > section:last-child {
    	height: 300px;
	}
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
    
}