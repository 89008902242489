body.location-nav-open{
	position: fixed;
	@include transform(translateX(-25%));
	@media (max-width: 767px){
		@include transform(translateX(-100%));
	}
	@media (min-width: 767px) and (max-width:992px){
		@include transform(translateX(-40%));	
	}	
}
body.main-nav-open{
	position: fixed;
}
body{
	/* @include transform(translateX(0)); */
	@include transition(0.5s ease);
}
.navbar{
	height: 115px;
	.navbar-brand {
	    padding: 15px;
	}

	button.navbar-toggle {
	    padding: 15px;
	    @media (max-width: 767px){
	    	margin-bottom: 0;
	    }
	}
	button.navbar-toggle.location-nav-btn {
		background:url('../images/location-btn.png');
		background-repeat: no-repeat;
		background-position: 95%;
    	padding: 12px 90px 12px 20px;
    	max-height: 51.5px;
    	text-align: right;
    	line-height: 1em;
    }
	a.navbar-toggle {
	    padding: 15px 2%;
	}
	.navbar-toggle .icon-bar {
	    border-radius: 5px;
	    height: 4px;
	}
}


.location-navigation{
	@media (max-width:767px){
		width: 100%;
	}
	@media (min-width: 767px) and (max-width:992px){
		width: 40%;
	}	
	width: 25%;
	position: fixed;
	overflow-y: scroll;
    margin: 0 auto;
    top: 0;
    left: auto;
    right: 0;
    height: 100%;
    background: $extra-dark-green;
    text-align: center;
    -webkit-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    a{
    	color: #fff;
    	text-decoration: none;
    }
    .navbar-header{
    	background-color: $medium-green;
    	height: 115px;
    	padding: 5px;
    }
    .nav-icons {
    	a{
    		text-decoration: none;
    	}
		a span.fa-stack {
			color: #fff;
		}
		a span{
			color: $extra-light-green;
			font-weight: $light;
		}
		.icon{
		    span {
		    	display: block;
		    	margin: 0 auto;
			} 	
		}
		.fa-xl{
			font-size: 2em;
		}   
	}
	ul {
	    color: #fff;
	    list-style-type: none;
	    text-align: left;
	    padding-left: 0;
	}
	ul li {
	    padding: 20px 20px 20px 60px;
	    border-bottom: 1px solid $dark-grey;
	    list-style-position: inside;
	    span{
	    	color: $dark-grey;
	    }
	     &:hover{
	    	background-color: rgba(63, 72, 62, 1);
	    	background-position-x: 10px;
	    	padding: 20px 20px 20px 55px;
	    	border-left: 5px solid $medium-green;
	    	span{
	    		color: $light-green;
	    	}
    	}
	}
	.alignright{
		float: right;
	}
	.location-main-navigation{
		.fa{
			line-height: 25px;
		}
	}
}

.collapsed-navbar-toggle span{
	background-color: #fff;
}

.li-calender{
	background-image: url('../images/calender.png');
	background-repeat: no-repeat;
	background-position-x: 15px;
    background-position-y: 50%;
}
.li-programs{
	background-image: url('../images/li-programs.png');
	background-repeat: no-repeat;
	background-position-x: 15px;
    background-position-y: 50%;
}
.li-requirements{
	background-image: url('../images/li-requirements.png');
	background-repeat: no-repeat;
	background-position-x: 15px;
    background-position-y: 50%;
}
.li-location-info{
	background-image: url('../images/li-location-info.png');
	background-repeat: no-repeat;
	background-position-x: 15px;
    background-position-y: 50%;
}
.li-change-locations{
	background-image: url('../images/li-change-location.png');
	background-repeat: no-repeat;
	background-position-x: 15px;
    background-position-y: 50%;
}

//.main-navigation.open{
//	transform: translateX(80%);
//}


.navbar-header:before {
    content: '';
    width: 0;
    height: 0;
    left: -1px;
    top: 42.5px;
    position: absolute;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #fff;
}


body.location-nav-open {
	#page-header,.wrap, footer {
    	opacity: 0.5;
    	pointer-events: none;
	}
	.main-nav-toggle{
		pointer-events: none;	
	}
}
header{
	nav#main-navigation {
		position: fixed;
		overflow-x: hidden;
		overflow-y: scroll;
	    width: 100%;
	    height: 100%;
	    background: $extra-light-green;
	    left: 0;
	    right: 0;
	    @include transition(all 0.06s ease-in-out);
	    .alignright{
	    	@media (max-width: 767px){
				float: right
	    	}
	    }
	    .carbon_footerwidget,
	    .carbon_locationhourswidget{
	    	border-top: 2px solid $grey;
	    	margin: 10px auto;
	    	padding: 10px;
	    	p{
		    	font-size: 15px;
	    		line-height: 24px;
	    		font-weight: 300;
				color: $dark-grey;
	    		text-decoration: none;
    		}
    		a{
    			color: $medium-green;
    			text-decoration: none;
    		}
		}
	    .navbar-nav{
	    	margin-top:0;
	    }
	    i.fa:not(.fa-app--logo) {
	    	border-radius: 50%;
	    	border: 3px solid $medium-green;
	    	padding: 10px;
	    	&:before {
	    		font-size: 20px;
	    		color: $medium-green;
			}
		}
	    ul:not(.fgl-dropdown-menu){
	    	> li{
	    		border-bottom: 1px solid $grey;
	    		margin-left: 10px;
    			margin-right: 10px;
	    	}
			> li > a{
		    	color: $medium-green;
		    	font-size: 17px;
		    	text-decoration: none;
		    	padding-top: 15px;
		    	padding-bottom: 15px;
		    	font-weight: 500;
		    	&:hover{
		    		color: #000;
		    	}
	    	}
	    }
	    .fgl-dropdown-menu{
	    	li > a{
	    		font-size: 15px;
	    		color: $dark-grey;
	    		text-decoration: none;
	    		&:hover{
	    			text-decoration: underline;
	    		}
	    	}
	    }
	    a.dropdown-toggle {
    		text-indent: 5px;
		}
	    .open > .fgl-dropdown-menu {
	    	display: block;
	    	margin: 10px auto 20px;
	    }
	    .fgl-dropdown-menu {
	    	display: none;
		    list-style: none;
		    font-size: 14px;
		    text-align: left;
		    text-decoration: none;
		}
	}
}

.navbar-toggle {
	margin-top: 30.5px;
	margin-bottom: 30.5px;

  .icon-bar {
    width: 30px;
    @include transition(all 0.2s);
  }
  .top-bar {
  	@include transform(translateX(4px) rotate(45deg));
    transform-origin: 10% 10%;
  }
  .middle-bar {
    opacity: 0;
  }
  .bottom-bar {
    @include transform(translateX(4px) rotate(-45deg));
    transform-origin: 10% 90%;
  }
}

.navbar-toggle.collapsed {
  .top-bar {
    @include transform(rotate(0));
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    @include transform(rotate(0));
  }
}

.mobile-app > p > img {
    margin: 5px;
}

.nav-heading{
	font-size: 17px;
}
/* .nav-justified>li, .nav-tabs.nav-justified>li {
    vertical-align: middle;
} */