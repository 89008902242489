footer{
	.top-footer{
		background-color: $light-grey;
		padding: 50px;
	}
	p{
		color: $gravel;
		font-size: 1em;
		line-height: 16px;
	}
	a{
	    color: $medium-green;
	    text-decoration: none;
	}

	a:active{
	    color: $dark-green;
	}

	a:hover{
	    color: $dark-green;
	}
	i.fa {
    	border-radius: 50%;
    	border: 3px solid $medium-green;
    	padding: 10px;
    	margin: 5px;
	}
	i.fa:before {
    	font-size: 20px;
    	color: $medium-green;
	}
	section.widget.nav_menu-2.widget_nav_menu > h5 {
    	color: $orange;
	}
	.menu {
    	list-style-type: none;
    	padding: 0;
    	li,a{
    		color: $gravel;
    		font-size: 1em;
    	}
	}
	.widget-col3{
		.carbon_footerwidget-8.carbon_footerwidget {
    		margin-top: 50px;
		}
	}
	.bottom-footer{
		background-color: $medium-grey;
		padding: 10px 50px;
		font-size: 12px;
	}	
	.portal-logins p {
    	margin: 25px 0;
	}
	.call-us{
		max-width: 450px;
		margin: 0 auto;
		@media (min-width: 767px){
			max-width: 300px;
			margin: 0;
		}
	}
} 