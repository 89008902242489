.tab-header {
    padding: 5% 0;
}
.nav-btn-pills li a {
    width: 50%;
    margin: 0 auto;
}
.nav.locations li a {
    padding: 20px 0;
}
.locations.nav-pills.blog-nav{
	a{
		margin: 0;
	}
}

.locations.nav-pills{
	border-bottom: 1px solid $extra-light-green;
	a{
		color: $dark-green;
		text-decoration: none;
		margin: 0 20%;
		@include transition( all 0.1s);
	}
}

.locations.nav-pills>li.active>a{
	color: $medium-green;
}
.locations.nav-pills.blog-nav>li.active>a,
.locations.nav-pills.blog-nav>li.active>a:focus, 
.locations.nav-pills.blog-nav>li.active>a:hover,
.locations.nav-pills.blog-nav>li>a:focus, 
.locations.nav-pills.blog-nav>li>a:hover {
	@extend .locations-nav-pills-active-hover-blog-nav;
}

.locations.nav-pills>li.active>a,
.locations.nav-pills>li.active>a:focus, 
.locations.nav-pills>li.active>a:hover,
.locations.nav-pills>li>a:focus, 
.locations.nav-pills>li>a:hover {
	@extend .locations-nav-pills-active-hover;
}

.nav-btn-pills.nav-pills>li.active>a,
.nav-btn-pills.nav-pills>li.active>a:focus, 
.nav-btn-pills.nav-pills>li.active>a:hover,
.nav-btn-pills.nav-pills>li>a:focus, 
.nav-btn-pills.nav-pills>li>a:hover{
	@extend .nav-btn-pills-active-hover;
}
.location-page-header-content{
	.fa-ul .fa-li {
    	left: 0;
    	top: 0;
    	margin-left: 0;
	}
	.fa-ul li{
		font-size: 14px;
	}
}

hr.location-divider {
    height: 1px;
    color: #fff;
    padding: 0;
    margin: 10px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

#programs-tab,
#requirements-tab{
	#teens,
	#eighteen{
		h3 {
	    	margin: 0 auto;
	    	padding: 20px 0 100px 0;
	    	color: $medium-green;
	    	font-weight: $light;
	    	text-transform: none;
		}
	}
	.nav{
		margin: 0 auto;
		max-width: 50%;
		@media (max-width: 767px){
			max-width: 100%;
		}
	}
	.nav-btn-pills li a {
    	width: 90%;
    	margin: 0 auto;
	}
	.tab-header{
		a.btn.btn-transparent-white-green-border {
	    	padding: 20px 5%;
	    	font-size: 28px;
		}
	}
	.btn-orange {
    	padding: 15px;
    	width: 100%;
	}
	.class-heading{
		color: $medium-green;
	}
}
#requirements-tab{
	.location-requirements{
		.row {
		    border-bottom: 1px solid $light-grey;
		    padding: 50px 0;
			}
	}	
	h3.option{
			color: $medium-green;
			text-transform: capitalize;;
		}
	h3.requirements{
		text-transform: capitalize;
		font-weight: $light;
	}
	.btn{
		width: auto;
	}
	.options-list {
		ul{
			list-style:none;
			padding-left: 1em;
			li::before{
				content: "\2022";
			    color: $medium-green;
			    display: inline-block;
			    width: 1em;
			    font-size: 1em;
			    margin-left: -1em;
			}
		}
		p{
			color: $medium-green;
			font-weight: $regular;
			margin-left: -1em;
			padding-left: 1em;
		}
	}
}
.programs-additional-options,
.programs-better-experience{
	.row {
		@media(min-width: 767px){
			display: flex;
		    margin: 0 auto;
		    padding: 50px;
		    text-align: center;
		    flex-direction: row;
		}
	}
	.col-sm-4,
	.table-box {
		@include transition(all 0.3s);
		@media(min-width: 767px){
	    	align-self: center;
		}
	}
	.table-box{
    	flex: 1 50%;
		@media(max-width:767px){
		    border-bottom: 1px solid $extra-light-green;
		    padding: 25px;
		}
	}
	h4{
		padding: 50px 0 0;
	}
	.row .col-sm-4:nth-child(1),
	.row .col-sm-4:nth-child(2)  {
		@media (min-width: 767px){
    		border-right: 1px solid $extra-light-green;
    	}
	}

	>.row:nth-child(2) {
		@media (min-width: 767px){
    		border-bottom: 1px solid $extra-light-green;
    	}
	}
}
.programs-additional-options{
	p,img{
		display: inline-block;
		margin: 10px;
	}
	@media (min-width: 767px){
		> .row:after {
    	content: '';
    	border-right: 1px solid $extra-light-green;
    	height: 100px;
    	position: relative;
    	left: -50%;
		}
	}
}
.programs-better-experience-mobile{
	.experience-header{
		margin: 50px auto;
		p,h3{
			margin: 10px auto;
		}
	}

}
.programs-better-experience{
	.experience-header{
		padding: 70px 20px 0;
		p,h3{
			margin: 10px auto;
		}
	}
	p,img{
		margin: 40px auto;
	}
	.col-sm-4 {
	    vertical-align: baseline;
	}
	.box-hover:hover{
		.front{
			img,p{
				opacity: 0;
			}
		}
		span.text-content {
	  		opacity: 1;	
		} 
	}
	span.text-content {
		top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    opacity: 0;
	 	background: $medium-green;
	    color: #fff;
	    position: absolute;
	    text-align: center;
	    margin: 0 auto;
	    padding: 0 5%;
	    word-wrap: break-word;
	    overflow: hidden;
		@include transition(opacity 500ms);
		p{
			margin: 20px auto;
			font-size: 1em;
			line-height: 1.5em;
		}
	}
}

.programs-adhd-section{
	background-color: $light-grey;
	@media (min-width: 767px){
		padding: 50px 100px;
	}
	@media (max-width: 767px){
		padding: 10%;
	}
	@media (min-width: 997px){
		.adhd-footer {
    		max-width: 75%;
		}
	}
	.adhd-row{
		background-image: url('../images/adhd-face.png');
		background-position: top right;
		background-size: contain;
		@media (max-width: 992px){
			background-size: 25%;
		}
		background-repeat: no-repeat;
		padding: 50px;
		background-color: #fff;
		box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
	}
	.adhd-header{
		h4{
			color: $medium-green;
		}
	}
	.fa{
		color: $medium-green;
		font-size: 25px;
	}
	.fa-ul>li {
    	margin: 25px 0;
	}
}

.location-footer {
	height: 580px;
    background-color: $extra-light-green;
    @media (max-width: 767px){
    	padding: 50px;
	}
    @media (min-width: 767px){
    	border-left: 5px solid #fff;
    	border-bottom: 10px solid #fff;
	}
    .fa{
    	color: $medium-green;
    	font-size: 30px;
    	top:0;
    }
 }
 .location-footer-content{
 	@include vertical-align(absolute);
 }
 .location-footer-header {
    padding-bottom: 5%;
}
.location-footer-phone{
	padding: 10% 0;
	.fa {
	    padding: 10% 0;
	}
}

.requirement-buttons {
	.btn{
		padding: 10px 5%;
	}
    padding: 20px 0;
    border-bottom: 2px solid $light-grey;
    @media (min-width: 767px){
	 margin-bottom: 50px;
    }
}

#location-mobile-hero{
	height: 300px;
	width: 100%;
}
.hours{
	max-width: 300px;
	margin: 0 auto;
}
.single-location{
	span.select2.select2-container {
    	min-width: 90%;
    	max-width: 90%;
    	display: block;
    	margin: 50px auto;
	}
}
.class-outline {
    	border: 1px solid $alto;
    	padding:0;
    	margin-bottom: 100px;
    	box-shadow: 0px 10px 20px rgba(0,0,0,.1);
	}
.class-outline-header {
	padding: 80px 30px 0;
	img.class-outline-icon {
		position: absolute;
		top: -25px;
	}
}
.class-outline-icon {
	position: absolute;
	top: -25px;
}
.class-outline-content{
	padding: 30px;
}
.class-outline-footer {
	padding: 30px;
	text-align: center;
	background-color: $medium-grey;
}
#change-location{
	color: $medium-green;
}
.change-location-container {
	@media (max-width: 767px){
		margin-bottom: 25px;
	}
}

//print
@media print{
	.location-calendar-container{
		a[href]:after {
    		//content: " (" attr(href) ")";
    		content: '' !important;
		}
		.fc-toolbar{
			display: none;
		}
	}
}
