.single-post{
	article.post{
		header{
			margin-bottom: 60px;
		}
		img.wp-post-image{
			width: 100%;
		}
		.entry-title{
			margin: 0 auto;
		}
	}
	.entry-share-btns::before {
	    content: 'Share:';
	    font-weight: 600;
	}

	.entry-share-btns {
	    padding: 25px;
	}

	.entry-share-btns li:last-child {
	    display: none;
	}
}
.blog-header{
	height: 135px;
	display: table;
	width: 100%;
	text-align: center;
	background-image: url('../images/green-pattern-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	.blog-category-title{
		display: table-cell;
		margin: 0 auto;
		float: none;
		vertical-align: middle;
	}
}

.sidebar,
.entry-title{
	a{
		color: #000;
		text-decoration: none;
	}
}
.sidebar,
.entry-title{
	a:hover{
		text-decoration: underline;
	}
}
.entry-summary{
	a{
		text-decoration: none;
	}	
	a:hover{
		text-decoration: underline;
	}
}

.article-content {
    	margin: 0 auto;
    	text-align: center;
    	img{
    		margin: 0 auto;
    	}
    	.entry-meta {
    		margin: 5% 0;
		}
}

hr.divider {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

ul.sage-pagination {
    padding: 0;
    text-decoration: none;
    text-align: center;
}
.sage-pagination {
	li {
    	display: inline-block;
    	margin: 1%;
	}
	a{
		text-decoration: none;
	}
	a:hover{
		text-decoration: underline;
	}
}
.post-pagination,
.sage-pagination {
    margin: 50px auto;
    .btn{
    	padding: 10px 5%;
    }
    .fa-angle-left {
    	margin: 0 10px 0 -10px;
	}
	.fa-angle-right {
    	margin: 0 -10px 0 10px;
	}
}

.article-outline {
	border: 1px solid $alto;
    box-shadow: 0px 10px 20px rgba(0,0,0,.1);
	padding:0;
	margin-bottom: 100px;
	margin: 50px auto;
	.btn-orange {
		padding: 15px;
		width: 100%;
	}
}
.article-outline-header {
	padding: 80px 30px 0;
	img.article-outline-icon {
		position: absolute;
		top: -25px;
	}
}
.article-outline-content{
	padding: 30px;
}
.article-outline-footer {
	padding: 30px;
	text-align: center;
	background-color: $medium-grey;
}
.article-heading{
	color: $medium-green;
}
header.post-header-archive{
	height: 500px;
	background-repeat: no-repeat;
	background-size: cover;
}
.article-outline-header{
	display: table;
	width: 100%;
	height: 120px;
	padding: 40px;
}
.article-outline-header .col-sm-6 {
    display: table-cell;
    vertical-align: middle;
    float: none;
}
.article-outline-footer > .row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    @media (max-width: 767px){
    	flex-direction: column;
    }
    width: 100%;
}
.article-outline-footer > .row > div {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 767px){
    	justify-content: center;
    	margin-top: 10px;
    }
    align-items: center;
}
.sage-pagination{
	a{
		&:hover{
			text-decoration: none;
		}
		@extend .btn-transparent-white-green-border;
		    text-decoration: none;
    		padding: 10px 5%;
    		border-radius: 5px;
	}
}