.plus,
.minus {
  position: absolute;
  right: 5%;
  transition: .3s all ease-out;
  @media(max-width: 768px){
    margin-right: 15px;
  }
}
.fgl-panel{
    a{
      .plus,.minus{
        color: $clay-ash;
      }
      &:hover{
          .plus,.minus{
            color: $medium-green;
          }
      }
    } 
    > a[aria-expanded="false"]{
      .plus {
        opacity: 1;
        transform: rotate(0deg);
      }
      .minus {
        opacity: 0;
        transform: rotate(-90deg);
      }
    }
    > a[aria-expanded="true"]{
      .minus {
        opacity: 1;
        transform: rotate(0deg);
      }
      .plus {
        opacity: 0;
        transform: rotate(90deg);
      }
    }
}
.panel-group{
  margin: 50px 25px;
  &.border-bottom-all{
    border-bottom: 2px solid $extra-light-green;
  }
  &:not(.border-bottom-all){
    &:last-child{
      border-bottom: 2px solid $extra-light-green;
    }
  }
  .fgl-panel {
    border-top: 2px solid $extra-light-green;
    border-radius: 0;
  }
}
.panel-heading {
    display: flex;
    align-items: center;
    flex-direction: row;
    .panel-title{
      white-space: normal;
      word-break: break-word;
      width: 95%;
    }
}
.single-location{
  .panel-heading:not(.panel-heading--faq){
    .panel-title {
      height: 100px;
    }
  }
  .panel-image{
    width: 25%;
  }
}
