
//Home Page
.home{
     .page-header-hero{
        background-position: center bottom;
     }
    h1.home-title{
        font-size: 46px;
    }
}
.home-title{
    font-size: 46px;
}
.section.adhd {
    background-size: contain !important;
    background-position: center right !important;
    @media (min-width: 767px){
        padding: 50px;
    }
}

.section.our-company {
    background-size: contain !important;
    background-position: center left !important;
    margin: 50px 0;
    @media (max-width: 767px){
        margin: 0 auto;
    }
}

.testimonial-section {
    @media (min-width: 767px){
        padding: 60px;
    }
    margin: 40px;
}
.in-the-news > .flexible-content {
    padding: 1% 0;
}
/* .in-the-news > .row-heading {
    padding-bottom: 50px;
} */
.in-the-news-row{
	padding-bottom: 3%;
}

#featured-videos,
#instructional-videos {
    margin: 0 auto 50px;
}
.featured-video-left {
    padding: 0;
}
.featured-video-right {
    background-color: #fff;
    overflow: auto;
    .featured-video-right__content{
        @media (min-width: 767px){
            position: absolute;
            top: 20px;
            padding: 5%;
        }
         @media (max-width: 767px){
            padding: 20px;
        }
    }
}

.featured-video-container{
    margin: 50px auto
}
.page-template-template-resources{
    .main{
        >.tab-content,.nav{
            background-color: $pampas;
        }
        >.tab-content{
            padding-bottom: 50px;
        }
    }
}
.quick-links-row {
    margin-top: 50px;
}


//home

.home{
    #page-hero{
            box-shadow: inset 0 0 0 1000px rgba(000, 0, 0, 0.3);
    }
}