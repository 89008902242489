@media (max-width: 767px){
	.testimonial-section{
		> .row{
			padding-bottom: 50px;
		}
	}
	.in-the-news.mobile-margin {
    	margin-bottom: 0;
	}
	.in-the-news>.flexible-content {
    	padding: 1% 0;
    	display: flex;
    	flex-wrap: wrap;
    	.col-sm-15{
    		flex: 1 50%;
    	}
	}
}