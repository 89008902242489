.btn{
	font-weight: $semi-bold;
}
.btn-light-green{
	background-color: $extra-light-green;
	color: $dark-green-btn;
	&:active{
		background-color: $extra-light-green;
		color: $dark-green-btn;
	}
	&:hover{
		background-color: $dark-green-btn !important;
		color: #fff;
	}
}
.btn-dark-green{
	background-color: $dark-green;
	color: #fff;
	padding: 15px 10%;
    border-radius: 5px;
    margin: 5% 0;
	&:active{
		background-color: $dark-green;
		color: #fff;
	}
	&:hover{
		background-color: #000 !important;
		color: #fff;
	}
}
.gform_button{
	@extend .btn;
	@extend .btn-dark-green;
}
.btn-orange{
	background-color: $orange;
	color: #fff;
	&:active{
		background-color: $orange;
		color: #fff;
	}
	&:hover{
		background-color: #000;
		color: #fff;
	}
}
.btn-orange-location.active{
	background-color: $orange;
	color: #fff;
	border: 2px solid $orange;
	&:active{
		@extend .btn-orange;
	}
}
.btn-orange-location{
	font-size: 12px;
	margin: 20px 0 5px 0;
	background-color: transparent;
	color: $orange;
	border: 2px solid $orange;
	height: 45px;
	&:active{
		@extend .btn-orange-location;
	}
	&:hover{
		@extend .btn-orange-location;
	}
	.fa-star {
    	font-size: 1.5em;
    	margin-right: 10px;
    	vertical-align: bottom;
	}
	.alignleft{
		margin-bottom: 0;
	}
}
.btn-solid-white-green-border{
	background-color: #fff;	
	border: 1px solid $extra-light-green;
	color: $dark-green-btn;
	&:active{
		background-color: #fff;
		border: 1px solid $extra-light-green;
		color: $dark-green-btn;
	}
	&:hover{
		background-color: $medium-green;
		border: 1px solid $medium-green;
		color: #fff;
	}
}
.btn-transparent-white-green-border{
	background-color: #fff;	
	border: 1px solid $extra-light-green;
	color: $dark-green-btn;
	&:active{
		background-color: #fff;
		border: 1px solid $extra-light-green;
		color: $dark-green-btn;
	}
	&:hover{
		background-color: $light-green-btn;
		border: 1px solid $medium-green;
		color: $medium-green;
	}
}
.calendar-button{
	background-color: #fff;	
	border: 1px solid $extra-light-green;
	color: $dark-green-btn;
	&:active{
		background-color: $extra-light-green;
		color: $dark-green-btn;
	}
	&:hover{
		background-color: $extra-light-green;
		color: $dark-green-btn;
	}
}
.nav-btn-pills-active-hover{
	background-color: $light-green-btn;
	border: 1px solid $medium-green;
	color: $medium-green;
}
.locations-nav-pills-active-hover-blog-nav{
	border-radius: 0;
    color: $medium-green;
    background-color: transparent;
    margin: 0 1%;
}
.locations-nav-pills-active-hover{
	border-radius: 0;
    color: $medium-green;
    background-color: transparent;
    border-bottom: 9px solid $medium-green;
    margin: 0 20%;
}
.nav-toggle:hover{
	cursor: pointer;
}

.fa-circle.icon-bg {
    color: transparent;
}
.fa-circle-thin.icon-bg {
    color: $light-green;
}

.navbar button.navbar-toggle.location-nav-btn-mobile {
    background: url(../images/map.svg);
    background-color: $extra-light-green;
    background-repeat: no-repeat;
    background-position: 5%;
    background-size: 45px;
    width: 100%;
    margin: 0 auto;
    padding-left: 25%;
    max-height: 51.5px;
    line-height: 1em;
    text-align: left;
    border: none;
    border-radius: 0;
    .alignright{
    	float: right;
    }
    .fa{
    	position: absolute;
    	top: 50%;
    	transform: translateY(-50%);
    }
}

.social-icons{
	a:hover{
		text-decoration: none;
	}
	i.fa {
    	border-radius: 50%;
    	border: 3px solid $medium-green;
    	padding: 10px;
    	margin: 5px;
	}
}
.btn-app{
	border: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: auto;
    max-width: 150px;
    width: auto;
    padding: 5px 10px;
    &.btn-app--double{
    	float: left;
    	margin: 10px 5px 0 0;
    }
    .btn-app--title-conainer {
    	display: flex;
    	flex-direction: column;
	}
	.btn-app--small {
    	font-size: 0.65em;
    	line-height: 1;
	}
	.btn-app--title {
    	font-size: 1.2em;
    	line-height: 1.2;
	}
	.btn-app--logo {
    	align-self: center;
    	margin: 0 5px 0 0;
	}
}