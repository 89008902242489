.entry-share-btns {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0px;
}
.entry-share-btns li {
  display: inline-block;
  margin: 0 6px 0 0;
  border-radius: 6px;
  background: none;
}
.entry-share-btns li:hover,
.entry-share-btns li:focus {
  background: none;
}
.entry-share-btns a,
.entry-content .entry-share-btns a {
  line-height: 25px;
  display: block;
  padding-right: 6px;
  padding-left: 6px;
  text-decoration: none;
  color: $dark-grey;
  border: none;
}
.entry-share-btns a:hover,
.entry-share-btns a:focus {
  text-decoration: none;
}
.entry-share-btns b {
  display: none;
}
.entry-share-btns .count {
  display: inline-block;
  margin-left: 2px;
  padding-left: 5px;
  border-left: 1px solid #555;
}
.entry-share-btns li:hover .count {
  border-left: 1px solid #444;
}
.entry-share-btns svg {
  >path{
    fill: $dark-grey;
  }
  display: inline-block;
  text-align: center;
  vertical-align: -3px;
}