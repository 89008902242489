/* .select2-search--dropdown{
	display: none;
} */
.select2-container{
	min-width: 100%;
	font-weight: 300;
}
.select2-results__option{
	font-size: 2em;
	@media (max-width: 767px){
		font-size: 1.5em;
	}
	padding-left: 5%;
	line-height: 1.5em;
}
span.select2-selection__arrow{
	min-width: 20%;
	&:before{
		content: '';
		position: absolute;
		background: $extra-light-green;
	    height: 80%;
	    top: 10%;
		width: 1px;
	    left: 0;
	}
	.fa{
		position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
	    left: 40%;
	    color: $clay-ash;
	}
}
.select2-container--default .select2-results>.select2-results__options {
    max-height: 100%;
}
.select2-container--default .select2-selection--single{
	border: 1px solid $extra-light-green;
}
.select2-container--default{
	.select2-selection--single .select2-selection__rendered{
		line-height: 70px;
		font-size: 2em;
		@media (max-width: 767px){
			font-size: 1.5em;
		}
		padding-left: 5%;
	}
	.select2-selection--single,
	.select2-selection--single .select2-selection__arrow{
		height: 70px;
	}
}
.section.container-fluid.location-select-container:before {
    content: '';
    height: 100px;
    @media (max-width: 767px){
    	height: 400px;
    }
    background: url('../images/green-pattern-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
.location-select-container {
	> .row{
		padding: 20px 0;
		background-color: #fff;
		width: 90%;
		max-width: 1250px;
		margin: -150px auto 40px;
		@media (max-width: 767px){
			margin: -380px auto 25px;
			display: flex;
			flex-direction: column;
		}
		@media (min-width: 767px){
			display: table;
		}
		@media (min-width: 460px){
			box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.4);
		}
		p{
			margin: 0;
			color: $medium-green;
	    	font-size: 1.2em;
		}
		.btn {
	    	padding: 14px 40px;
	    	font-size: 2em;
	    }
	    > .col-location{
	    	@media (min-width: 767px){
				display: table-cell;
				vertical-align: middle;
				float: none;
				margin: 0;
			}
			@media (max-width: 767px){
				margin: 10px 0 !important;
			}
		}
	}
}

span#select2-state-select-container:active,
span#select2-state-select-container:focus,
span.select2.select2-container.select2-container--default.select2-container--above.select2-container--open.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--open.select2-container--default .select2-selection--single .select2-selection__rendered, 
.select2-container--open.select2-container--default .select2-selection--single {
    background-color: $dark-green;
    color: #fff !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[aria-selected]:active,
.select2-container--default .select2-results__option--highlighted[aria-selected]:focus{
	background-color: $extra-light-green;
	color: $medium-green;
}


@media (max-width: 767px){
	.location-select-container > .row > .col-location {
    	display: block;
    	margin: 5% 0;
	}
}
.single-location{
	.fc-center{
		span.select2.select2-container {
	    	margin: 20px auto;
		}
		.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__arrow {
    		height: 50px;
		}
		.select2-container--default .select2-selection--single .select2-selection__rendered {
    		line-height: 50px;
    		font-size: 2em;
    		padding-left: 5%;
    	}
    	li.select2-results__option{
			font-size: 1.5em;
		}
	}
	#select2-change-location-results{
		li.select2-results__option{
			font-size: 1em;
		}
	}
	li.select2-results__option {
    	font-size: 1.3em;
	}
	.change-location-container{
		span#select2-change-location-container:before {
	    	content: "\00BB \0020";
	    	color: #fff;
		}
		li.select2-results__option{
			font-size: 1em;
		}
		.select2-container--default .select2-selection--single{
			border: none;
			background: transparent;
			color: #fff;
		}
		.select2-container--default .select2-selection--single .select2-selection__rendered {
			color: #fff;
	    	line-height: 2em;
	    	font-size: 1em;
		}
		.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__arrow {
	    	height: 2em;
		}
		.select2-selection--single .select2-selection__arrow{
			display: none;
		}
		span.select2.select2-container{
			@media (min-width: 767px){
				margin: 0;
				min-width: 150px;
			}
			margin: 0 auto;
			min-width: 175px;
		}
		span.select2-selection__arrow .fa-2x{
			font-size: 1em;
		}
	}
}

#select2-change-calendar-container {
	color: $dark-green;
	font-size: 1.5em;
	text-align: left;
	@media(max-width: 767px){
		text-align: left;
	}
}