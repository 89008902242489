svg#svg_arrow {
    width: 100px;
    height: 100px;
    fill-opacity: 1;
    background-color: #1D602D !important;
    padding: 15px;
}
path.svg_arrow--path {
    fill: #5BBB4B !important;
    fill-opacity: 1;
}
text.svg_arrow--text {
    fill: #fff !important;
    font-size:3em;
    text-anchor: middle;
    alignment-baseline: central;
    font-family:OpenSans-Bold, Open Sans;
    font-weight:700;
}