body:not(.new-york){
	.fc-fiveHourBtn-button{
		display: none !important;
	}
}
.new-york{
	.fc-eightHourBtn-button,
	.fc-thirtyHourBtn-button,
	.fc-roadTestBtn-button,
	.fc-center .select2-container{
		display: none !important;
	}
}
#calendar {
    margin: 50px auto;
    @media (max-width: 767px){
    	margin-top: 0;
    }
}

.fc-event{
    background-color: $orange;
    border: 1px solid $orange;
}
.fc-event-dot {
	background-color: $medium-green;
}
.fc-state-highlight{
	> .fc-day-number {
		color: #fff;
    	background-color: $medium-green;
    	border-radius: 100%;
    	width: 20px;
    	text-align: center;
	}
}

button{
	&.fc-state-active,
	&.fc-state-disabled{
		background-color: $extra-light-green;
		color: $dark-green-btn;
	}
	&.fc-button{
		@extend .calendar-button;
		text-transform: uppercase;
		background-image: none;
		box-shadow: none;
		@media (min-width: 767px){
			min-height: 45px;
			&:not(.fc-prev-button, .fc-next-button){
				width: 100px;
			}
		}
	}
}

.fc-toolbar{
	@media (max-width: 767px){
		display: flex;
    	flex-direction: column-reverse;
    	align-items: center;
    	.fc-button-group, 
    	button.fc-today-button {
    		margin: 20px;
		}
	}
	h2{
		font-size: 2em;
		color: $medium-green;
		font-weight: $light;
	}
	.fc-center {
	    display: flex;
	    justify-content: center;
	    flex-direction: column;
	    @media (max-width: 767px){
	    	flex-direction: column-reverse;
	    	width: 100%;
	    }
	}
}
.location-calendar{
	margin: 50px auto;
	table{
		width: 100%;
		text-align: center;
	}
	tr{
		&:hover{
			transition: 0.3s all;
			background-color: $light-grey;
		}
	}
	th,td{
		padding: 20px 0;
		border-bottom: 1px solid $medium-green;
	}
	th {
		background-color: $light-grey;
	    color: $medium-green;
	    text-align: center;
	}
	td {

	}
}