.home{
	.page-header-hero{
		background-position: center bottom !important;
	}
}
.page-header-hero {
	height: 585px;
	@media (max-width: 767px){
		height: 300px;
	}
}
#page-hero,
#location-page-hero {
    display: table;
    margin: auto;
    width: 100%;
    //box-shadow: inset 0 0 0 1000px rgba(000, 0, 0, 0.5);
    //box-shadow: inset 0px -140px rgba(51,154,34,1);
}

#page-hero {
	color: #fff;
	.page-header-content {
	    display: table-cell;
	    vertical-align: middle;
	    text-align: center;
	    float: none;
	}
	a{
		color: #fff;
	}
	h1{
		text-transform: uppercase;
		font-weight: $bold;
	}
}

.location-page-header-hero {
	height: 330px;
}
.location-page-header-content {
    	display: table-cell;
	    vertical-align: baseline;
	    text-align: left;
	    float: none;
	     a {
    	color: inherit;
    	text-decoration: none;
	}
}

.location-page-header-content-left {
	color: #fff;
    background-color: $medium-green;
    padding: 1% 3%;
}