.slick-dots li button:before{
	font-size: 20px;
}
.slick-dots li.slick-active button:before{
	background-color: $orange;
	border: 3px solid $orange;
    border-radius: 50%;
}
.slick-dots li button:before{
    content: '';
    color: #000;
    opacity: .25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 3px solid #000;
    border-radius: 50%;
}
.testimonials-slider{
	text-align: center;
	.quote-text{
		color: $medium-green;
	}
	.quote-name{
		line-height: 1;
		text-transform: uppercase;
		margin-top: 5%;
		margin-bottom: 0;
	}
	.quote-location{
		font-style: italic;
		color: $grey;
		line-height: 1;
		margin-bottom: 5%;
	}
}
img.quote-img{
	margin: 5% auto;
}

.slick-loading .slick-list{
    background: #fff url(../images/ajax-loader.gif) center center no-repeat !important;
}