@media (max-width: 992px){//tablet
	.location-page-header-content{
		.fa-ul{
			margin-left: 0;
		}
	}
	.mobile-heading{
		font-size: 3em;
		line-height: 1em;
	}
}
@media (max-width: 767px){ //mobile

	.mobile-flex-reverse > .row{
		display: flex;
		flex-direction: column-reverse;
	}
	.mobile-flex-container{
		display: flex;
		> *{
			flex: 1;
		}
	}
	.mobile-flex-column{
		display: flex;
		flex-direction: column;
		max-width: 95%;
    	margin: 0 auto;
	}
	.mobile-flex-center{
		display: flex !important;
		justify-content: center;
		align-items: center;
		align-self: center;
		alignment-baseline: central;
	}
	.mobile-flex-center-left{
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-self: center;
		alignment-baseline: central;
	}
	.mobile-flex-1{
		flex: 1;
	}
	.mobile-bg-hide{
		background-image: none !important;
	}
	.mobile-center > *,
	.mobile-center{
	    text-align: center !important;
	}

	.mobile-bg-green {
	    background-color: $extra-light-green !important;
	}

	.img-responsive{
	    margin: 0 auto;
	}
	.youtube-video > div > iframe {
	    width: 100%;
	}
	.mobile-margin{
		margin: 50px auto;
	}
	.table-image > img {
    	width: 10%;
	}
	.mobile-margin-5px{
		margin: 5px;
	}
	.mobile-extra-padding-bottom{
		padding-bottom: 50px;
	}
}